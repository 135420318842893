import styled, { device, themeGet } from "@styled";
// import blueWaveBg from "@data/images/bg/blue-wave-bg.webp";
// import orangewaveBg from "@data/images/bg/orangewaveBg.webp";

export const BoxSectionWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  padding:40px 0;
  ${device.medium}{
    padding:60px 0;
  }
  ${device.large}{ 
    padding:80px 0;
  }
  ${device.xlarge}{ 
    padding:100px 0;
  }
  .section-title {
    p {
      color: #fff;
    }
  }
`;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const BoxSectionWrapInner = styled.div`
  min-height: 185px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgba(210,210,210,0.55);
  perspective: 1000px;
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    transform: rotateY(180deg);
    .flipFront{
      opacity: 0;
      visibility: hidden;
    }
    .flipBack{
      opacity: 1;
    }
  }
`;
export const FlipFront = styled.div`
  padding: 30px 15px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.10196078431372549);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  backface-visibility: hidden;
  position: relative;
  height: 100%;
  z-index: 2;
  ${device.medium}{
     padding: 40px 24px;
  }
`;
export const FlipBack = styled.div`
  padding: 20px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.10196078431372549);
  transition: ${themeGet("transition")};
  opacity: 0;
  position: absolute;
  inset: 0px;
  height: 100%;
  transform: rotateY(180deg);
  transition: 0.3s
  ${device.medium}{
    padding: 30px 20px;
  }
`;
export const BoxSectionIconwrap = styled.div``;
export const ButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
`;
export const BoxSectionTextWrap = styled.div`
  h4 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 22px;
    }
  }
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }
`;
export const FlipList = styled.ul`
  list-style:disc;
  padding-left:20px;
`;

export const FlipItem = styled.li`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  color: #ccb8fe;
  font-weight: 700;
  ${device.medium}{
    font-size: 15px;
  }
  &::marker{
    color:#ffffff;
  }
  &:not(:last-child){
    margin-bottom:15px;
    ${device.medium}{
      margin-bottom:25px;
    }
  }
  span{
    display:block;
    margin-top:5px;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 1.4;
    color: #ccb8fe;
    font-weight: 400;
    ${device.medium}{
      font-size: 15px;
      margin-top:8px;
    }
  }
`;
