import styled, { themeGet, device } from "@styled";
export const StyledSection = styled.section`
    background-color: #f54e1c;
    padding-block: 40px;
    position: relative;
    isolation: isolate;
    overflow: hidden;
    ${device.medium} {
        padding-block: 60px;
    }
    ${device.large} {
        padding-block: 80px;
    }
`;

export const StartedTodayWrap = styled.div`
    max-width:850px;
    margin:0 auto;
    ${device.xxlarge} {
         max-width:950px;
    }
    ${device.xxlarge} {
        max-width:1078px;
    }
`

export const StyledHeading = styled.h3`
    margin-block-end: 10px;
    color: #fff;
    span {
        color: ${themeGet("colors.secondary")};
    }
    ${device.large} {
        margin-block-end: 0;
    }
`;

export const StyledBG = styled.div`
    position: absolute;
    right: -68px;
    top: 0;
    z-index: -1;
`;
export const TextDescription = styled.div`
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 1.3;
    color: #ffffff;
    margin:20px 0 30px;
    ${device.xxlarge} {
        font-size: 20px;
        margin:36px 0 42px;
    }
    ${device.xxlarge} {
        font-size: 22px;
        margin:45px 0 52px;
    }
`;