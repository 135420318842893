import React from "react";
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import Button from "@ui/button";
import { HeadingType, ButtonType } from "@utils/types";
import { StyledSection, StyledHeading, TextDescription, StartedTodayWrap } from "./style";
const CTAArea = ({ data }) => {
    return (
        <StyledSection>
            <Container>
                <StartedTodayWrap className="text-center">
                    {data?.headings?.[0] && (
                        <StyledHeading
                            as={data.headings[0]?.level}
                            dangerouslySetInnerHTML={{
                                __html: data.headings[0]?.content,
                            }}
                        />
                    )}
                    <TextDescription>{data?.section_title?.description}</TextDescription>
                    {data?.buttons?.map(({ id, content, ...rest }) => (
                        <Button key={id} m="7px" {...rest}>
                            {content}
                        </Button>
                    ))}
                </StartedTodayWrap>
            </Container>
        </StyledSection>
    );
};

CTAArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    }),
};

export default CTAArea;
