import styled, { css, device } from "@styled";
import blueWaveBg from "@data/images/bg/technology-blue-wave-bg.png";
export const SectionWrap = styled.section`
    padding: 100px 0 40px;
    background-image: url(${blueWaveBg});
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
    z-index:1;
    ${device.small} {
        padding: 120px 0 60px; 
    }
    ${device.large} {
        padding: 150px 0 80px;
    }
    ${device.xlarge} {
        padding: 166px 0 100px;
        background-position: right top;
    }
    ${(props) =>
        props.layout === 1 &&
        css`
        background-color:#f54e1c
        `}     
`;
export const TechnologyImgWrap = styled.div`
    text-align:center;
`
export const TechnologyListWrap = styled.div`
    padding-top:30px;
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    grid-template-rows:repeat(3, 1fr);
    grid-gap:10px;
    align-items:center;
    ${device.small} {
        grid-template-columns:repeat(3, 1fr);
        grid-template-rows:repeat(3, 1fr);
    }
    ${device.medium} {
        grid-template-columns:repeat(4, 1fr);
        grid-template-rows:repeat(2, 1fr);
    }
    ${device.large} {
        padding-top:40px;
        grid-template-columns:repeat(5, 1fr);
        grid-template-rows:repeat(2, 1fr);
    }
    ${device.xlarge} {
        padding-top:60px;
        grid-gap:20px;
    }
`
export const TechnologyListItem = styled.a`
    border-radius: 10px;
    background-color: rgba(255,255,255,0.10980392156862745);
    border: 1px solid rgba(210,210,210,0.55);
    min-height: 150px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding: 25px 10px 20px;
    transition:all .7s ease-in-out;
    width:100%;
    &:hover{
        transform: scale(1.05);
        background-color:rgb(249, 79, 25);
    }
    ${device.medium}{
        min-height: 175px;
    }
    ${device.large} {
        &:nth-child(2), &:nth-child(5), &:nth-child(8){
            grid-row:2;
        }
        &:nth-child(3){
            grid-row:1 / span 2;
            grid-column: 2;
        }
        &:nth-child(6){
            grid-row:1 / span 2;
            grid-column: 4;
        }
        
    }
    ${device.xlarge}{
        min-height: 232px;
        padding: 35px 15px 25px;
    }
    .technologyHeading{
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1.3;
        color: #ffffff;
        margin:0;
        font-weight:400;
        width:100%;
        transition:all .5s ease-in-out;
        ${device.large}{
            font-size:16px;
        }
        ${device.xlarge}{
            font-size:20px;
        }
    }
    img{
        max-height: 50px !important;
        object-fit: contain !important;
        max-width: 60px;
        ${device.medium}{
            max-height: 60px !important;
            max-width: 60px;
        }
        ${device.xlarge}{
            max-height: 75px !important;
            max-width: 75px;
        }
    }
`
export const HumanTouchBox = styled.div`
    border-radius: 10px;
    background-color: #2f2062;
    border: 1px solid rgba(255,255,255,0.55);
    padding:20px 0;
    margin-top:40px;
    text-align:center;
    position:relative;
    width: calc(100% - 8px);
    ${device.small}{
        width: calc(100% - 10px);
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        right: -8px;
        width: 100%;
        max-width: 100px;
        height: calc(100% - 25px);
        background-color: rgb(250, 79, 25);
        border-radius: 10px;
        z-index: -1;
        ${device.small}{
            bottom: -10px;
            right: -10px;
            max-width: 150px;
            height: calc(100% - 30px);
        }
        ${device.large}{
            max-width: 194px;
            height: calc(100% - 43px);
        }
    }
    ${device.medium}{
        padding:30px 0;
        margin-top:60px;
    }
    ${device.xlarge}{
        padding:48px 0;
        margin-top:80px;
    }
    h4 {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.2;
        color: #ffffff;
        font-weight: 700;
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
    .humanBtn {
        border-radius: 0;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #ffffff;
        font-weight: 700;
        transition:all .5s ease-in-out;
        &:hover{
            text-decoration:underline;
            color:#fff;
        }
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
`